import HeaderAbout from "../components/HeaderAbout/HeaderAbout";
import ContactUs from '../components/ContactUs/ContactUs';
import FullAboutUs from '../components/FullAboutUs/FullAboutUs';

const AboutPage = ({t}) => {
    return(
        <>
            <HeaderAbout t={t}/>
            <FullAboutUs  t={t}/>
            <ContactUs t={t}/>
        </>
    )
}

export default AboutPage;