import HeaderAbout from "../components/HeaderAbout/HeaderAbout";
import Feedback from "../components/Feedback/Feedback";

const FeedbackPage = ({t}) => {
    return(
        <>
            <HeaderAbout t={t}/>
            <Feedback t={t}/>
        </>
    )
}

export default FeedbackPage;