import './App.scss';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Footer from './components/Footer/Footer';
import Homepage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import FeedbackPage from './pages/FeedbackPage';
import i18next from 'i18next';
import { initReactI18next, useTranslation } from 'react-i18next';
import Social from "./components/Social/Social";


const translationEn = {
  hello: "Hello!!!",
  events: "Events",
  contact: "Contact Us",
  about: "About",
  about_us: "About Us",
  more: "See more",
  l_more: "Learn more",
  less: "See Less",
  back: "Back",

  //Months
  "january": "January",
  "february": "February",
  "march": "March",
  "april": "April",
  "may": "May",
  "june": "June",
  "july": "July",
  "august": "August",
  "september": "September",
  "october": "October",
  "november": "November",
  "december": "December",

  //Testimonials
  testimonials: "Testimonials",

  "m_testimonial_1": "We have come to this life, or rather God brought us here because we have a mission; we are not here by chance. Today, we can do something that may seem minimal to us, but for someone else, it could bring joy and even change their day.",
  "m_testimonial_2": " I grew up without a father, he died when I was 2 years old in an accident. I also grew up with a mother who was suffering a lot. All this led me to a life of vices; at the age of 12 I started drinking alcohol, using drugs and having sex.",

  "f_testimonial_1_1": "We have come to this life, or rather God brought us here because we have a mission; we are not here by chance. Today, we can do something that may seem minimal to us, but for someone else, it could bring joy and even change their day.",
  "f_testimonial_1_2": "I always knew I was meant to do something significant but never knew what until I received my calling from God. After a retreat, I felt as if God was calling me to create a group for young people in the Hispanic community of Wyandach. It took a lot of time, effort, and there were moments when it seemed impossible, but today, that group is a reality and has been thriving for over 7 years.",
  "f_testimonial_1_3": "In life, there are many obstacles, and no matter how difficult it may seem to reach the goal, one should never be discouraged. Always hold onto God's hand when you feel like you can't bend your knees, pray, entrust yourself, and you will see that everything will turn out well.",

  "f_testimonial_2": " I grew up without a father, he died when I was 2 years old in an accident. I also grew up with a mother who was suffering a lot. All this led me to a life of vices; at the age of 12 I started drinking alcohol, using drugs and having sex.",
  "f_testimonial_2_2": "The more I did the more I wanted and things only got worse as I grew up. I lived a life only for the pleasure of the body and even though at the time I felt good, there was something I was missing, a void that was not filled with anything.",
  "f_testimonial_2_3": "Although I didn't know my father, I had him as my idol and I admired him. When I discovered that he was not who I thought he was, I developed a deep hatred towards him, which affected me greatly." ,
  "f_testimonial_2_4": "My life hit rock bottom on November 19, 2019 when after a car accident I realized that I was harming myself and others. I knew that I could no longer continue like this and I had to make a big change in my life.",
  "f_testimonial_2_5": "Thanks to the youth group and God I have managed to completely transform my life, it has been a hard road but it has been worth it. If I could change, you can too.",

  //About
  a_p_1: "Welcome to Hijos de Dios!",
  a_p_2: "Seven years ago, Hijos de Dios was born as a sacred space for young Catholics from Wyandanch. Since then, we have grown and evolved together, keeping our commitment to faith and serving others at the heart of everything we do.",

  //Events
  monday: "Monday",
  tuesday: "Tuesday",
  wednesday: "Wednesday",
  thursday: "Thursday",
  friday: "Friday",
  saturday: "Saturday",
  sunday: "Sunday",

  e_desc_1: "Our weekly meeting will take place at 1434 Straight Path, Wyandanch, NY, United States, New York. It will be from 4PM to 6PM.",
  e_desc_2: "We will carry out a work of mercy in which we will visit the most needy and provide them with clothes and food. We will meet at 1434 Straight Path, Wyandanch, NY, United States, New York. It will be from 4PM to 6PM.",
  e_desc_3: "Our social day, which will be full of fun activities and you will get to know the group members better. 1434 Straight Path, Wyandanch, NY, United States, New York. It will be from 4PM to 6PM.",
  e_desc_4: "Our movie day, we will be watching a movie that will leave you speechless. 1434 Straight Path, Wyandanch, NY, United States, New York. It will be from 4PM to 6PM.",
  e_desc_5: "Inwood Parish Retreat, take advantage of this incredible opportunity to improve your spiritual life and meet our brothers from Inwood, it will be from 2pm to 6pm. 68 Wanser Ave, Inwood, NY 11096",
  e_desc_5: "Inwood Parish Retreat, take advantage of this incredible opportunity to improve your spiritual life and meet our brothers from Inwood, it will be from 2pm to 6pm. 68 Wanser Ave, Inwood, NY 11096",
  e_desc_9: "We have an important event coming up that you won't want to miss. This will be a fantastic opportunity for us to gather, learn, and grow together. Please check the WhatsApp group for more information and updates regarding the date, time, and location. Stay tuned for further details!", 
  e_desc_10: "We have an exciting trip coming up soon! This trip will be a great chance to bond with fellow members and experience something new together. Please check the WhatsApp group for more information, including the itinerary, packing list, and any necessary preparations. More details will be shared shortly, so keep an eye on the group messages!",
  e_desc_11: "We're going to play soccer this week, but we need to gather at the church first. The meeting will be at 1434 Straight Path, Wyandanch, NY, United States, New York, starting at 4 PM, and the game will last until 6-7 PM.",
  e_desc_6: "Our weekly meeting will take place at Belmont Park, but we will meet in the parking lot of our parish, 1434 Straight Path, Wyandanch, NY, United States, New York, at 4pm."





,

  //Contact
  name: "Name",
  number: "Number",
  email: "Email",
  message: "Message",
  submit: "Submit",
  success: 'Form submitted. We will be in touch shortly.',
  error: 'There was an error. Please try again.',

  //FAQ
  "faq_t_1": "Who are we?",
  "faq_desc_1": "We are a youth group from the Catholic Church committed to spiritual growth, community, and service.",

  "faq_t_2": "What is the purpose of our youth group?",
  "faq_desc_2": "Our purpose is to provide a welcoming environment where young people can grow in their faith, build meaningful friendships, and engage in activities that promote service and community.",

  "faq_t_3": "Is there a minimum age to be part of the youth group?",
  "faq_desc_3": "Our group is open to all youth between the ages of 18 and 35.",

  "faq_t_4": "When and where do we regularly meet?",
  "faq_desc_4": "Our meetings are every Sunday from 4 PM to 6 PM at the 'Our Lady of the Miraculous Medal' parish, located at ",
  "faq_direction": "1434 Straight Path, Wyandanch, NY, United States, New York.",

  "faq_t_5": "How can I get in touch with the youth group?",
  "faq_desc_5": "You can fill out the contact form or send us a message through our social media.",

  "faq_t_6": "Is it necessary to be a member of the church to join the youth group?",
  "faq_desc_6": "The youth group is open to everyone, without exclusion based on religion or participation within the church.",

  //Full about us
  full_t_1: 'About Us',
  full_p_1: 'Seven years ago, Sons of God was conceived as a spiritual haven for young Catholics in the Wyandanch parish community in New York. Throughout this time, we have experienced significant growth and collective transformation, steadfastly maintaining our commitment to faith and service to others.',

  full_t_2: 'Our Story',
  full_p_2__1: `In 2016, Sons of God began as a response to the need for a sacred space where Spanish-speaking youth could explore and strengthen their Catholic faith. This inspiring project was conceived after a spiritual retreat profoundly impacted Maria Rodriguez, who felt God's calling to initiate a youth group within our Hispanic community in Wyandanch.`,
  full_p_2__2: `The seed of this idea began to germinate after that transformative spiritual retreat. Maria's restless heart led her to research and seek guidance, interacting with various individuals to lay the foundations for this youth group. It was during this quest that Maria met Estefani, an invaluable collaborator who guided and inspired her to overcome one of the initial major obstacles. At that time, the parish priest did not see the need for a youth group.`,
  full_p_2__3: `With Estefani's help, Maria successfully laid the groundwork for the youth group in 2016. Together, they overcame challenges and found the support needed to advance in this mission. Despite initial obstacles, including the doubt of the parish priest, Maria, along with Elizabeth, Edith Salmerón, Jocelyn, José, and Alex, persevered. Through conversations and joint efforts, they eventually convinced the priest of the importance and necessity of a youth group in our community.`,
  full_p_2__4: 'Since then, every step we have taken as a community has been a shared adventure. We have lived numerous experiences, building meaningful bonds and forging a common path toward spiritual growth. This journey has been possible thanks to the joint effort and dedication of all who have contributed to the formation and continuous development of Sons of God.',

  full_t_3: 'Our Mission',
  full_p_3: 'Rooted in the fundamental values of the Catholic faith, our mission is to provide a welcoming and enriching environment where young people can cultivate their relationship with God while positively contributing to the surrounding community. We believe in the importance of prayer, service, and love as fundamental elements of our identity.',

  full_t_4: 'Our Commitment',
  full_p_4: 'We maintain an unwavering commitment to faith, prayer, and service to others. Every activity, event, and encounter is infused with the essence of these values, creating a space where young people can grow spiritually, strengthen friendships, and make a positive difference in the world around us.',

  full_t_5: 'Join Us',
  full_p_5__1: 'We invite you to join us on this exciting journey. Whether you are new to the faith or have been with us from the beginning, there is a place for you in our family. Explore our activities, participate in our events, and discover how you can be part of our mission as we walk towards our "First Love."',
  full_p_5__2: 'At Sons of God, we are committed to continuing to grow together, nurturing our souls, and extending the love of God to all those around us.',
  full_p_5__3: 'Welcome to our family!',

  //Footer
  follow: "Follow us",
  foot_desc: "If you are a young Catholic looking to strengthen your faith, serve others, and connect with people who share your values, we invite you to join Hijos de Dios. Regardless of your age or level of religious knowledge, you are welcome in our community. We hope to see you at our upcoming activities, sharing this exciting journey of faith and growth with us.",
  developed_by: "Website developed by",

  //Feedback
  fed_opinion: "Give us your opinion",
  fed_tell: "Tell us what you enjoyed about the group and if there are any activities you would like to see again. We value your feedback and strive to improve for our members.",
  fed_success: "Thanks, we are going to carefully read your feedback.",
  fed_anonimus: "All submisions are anonimus.",
  fed_back: "Go back to the main page"
  
}

const translationEs = {
  hello: "¡Hola!",
  events: "Eventos",
  contact: "Contáctanos",
  about: "Acerca",
  about_us: "Acerca de Nosotros",
  more: "Ver más",
  l_more: "Ver más",
  less: "Ver menos",
  back: "Atrás",

  //Meses
  "january": "Enero",
  "february": "Febrero",
  "march": "Marzo",
  "april": "Abril",
  "may": "Mayo",
  "june": "Junio",
  "july": "Julio",
  "august": "Agosto",
  "september": "Septiembre",
  "october": "Octubre",
  "november": "Noviembre",
  "december": "Diciembre",

  //Testimonios
  testimonials: "Testimonios",

  m_testimonial_1: "Hemos venido a esta vida, o mejor dicho Dios nos trajo porque tenemos una misión, no estamos aquí de por gusto, hoy podemos hacer algo que tal vez sea algo mínimo pero para otra persona es algo que le alegra el día, que le cambia incluso este. ",
  "m_testimonial_2": " Yo crecí sin padre, él murió cuando tenía 2 años en un accidente. También crecí con una madre que estaba sufriendo mucho. Todo esto me llevó a una vida de vicios; a los 12 años empecé a tomar alcohol, usar drogas y tener relaciones sexuales.",

  f_testimonial_1_1: "Hemos venido a esta vida, o mejor dicho Dios nos trajo porque tenemos una misión, no estamos aquí de por gusto, hoy podemos hacer algo que tal vez sea algo mínimo pero para otra persona es algo que le alegra el día, que le cambia incluso este.",
  f_testimonial_1_2: "Yo siempre supe que iba a hacer algo grande pero nunca supe qué hasta que recibí mi llamado de Dios. Después de un retiro sentí como si Dios me llamara a crear un grupo de jóvenes en la comunidad hispana de Wyandach. Tomó mucho tiempo, esfuerzo y hubo momentos en los que parecía imposible pero hoy ese grupo es una realidad y lleva más de 7 años.",
  f_testimonial_1_3: "En la vida hay muchos obstáculos y por más difícil que uno vea llegar a la meta, no hay que darse por vencido. Uno Siempre debe agarrarse de las manos de Dios cuando uno cree que no puede doblar rodillas, rezar, encomendarse y verá que todo saldrá bien.",

  "f_testimonial_2": " Yo crecí sin padre, él murió cuando tenía 2 años en un accidente. También crecí con una madre que estaba sufriendo mucho. Todo esto me llevó a una vida de vicios; a los 12 años empecé a tomar alcohol, usar drogas y tener relaciones sexuales.",
  "f_testimonial_2_2": "Mientras más hacía más quería y las cosas solo fueron a peor a medida que crecía. Vivía una vida sólo para el placer del cuerpo y a pesar de que en el momento me sentía bien, había algo que me hacía falta, un vacío que no se llenaba con nada.",
  "f_testimonial_2_3": "A pesar de que no conocía a mi padre lo tenía como mi ídolo y lo admiraba. Cuando descubrí que no era quien yo pensaba que era, desarrollé un profundo odio hacia él, el cual me afectó en gran medida.",
  "f_testimonial_2_4": "Mi vida tocó fondo el 19 de noviembre 2019 cuando después de un acidente de auto me di cuenta que estaba haciendo daño a mí mismo y a los demás. Sabia que ya no podía seguir así y tenía que hacer un gran cambio en mi vida.",
  "f_testimonial_2_5": "Gracias al grupo de jóvenes y a Dios he logrado transformar mi vida totalmente, ha sido un camino duro pero ha valido la pena. Si yo pude cambiar, tú también puedes.",

  //Acerca de
  a_p_1: "¡Bienvenido a Hijos de Dios!",
  a_p_2: "Hace siete años, Hijos de Dios nació como un espacio sagrado para jóvenes católicos de Wyandanch. Desde entonces, hemos crecido y evolucionado juntos, manteniendo nuestro compromiso con la fe y el servicio a los demás en el corazón de todo lo que hacemos.",

  //Eventos
  monday: "Lunes",
  tuesday: "Martes",
  wednesday: "Miércoles",
  thursday: "Jueves",
  friday: "Viernes",
  saturday: "Sábado",
  sunday: "Domingo",

  e_desc_1: "Nuestra reunión semanal tendrá lugar en 1434 Straight Path, Wyandanch, NY, United States, New York. Será de 4PM a 6PM.",
  e_desc_2: "Llevaremos a cabo una obra de misericordia en la cual visitaremos a los más necesitados y les proporcionaremos ropa y comida. Nos reuniremos en 1434 Straight Path, Wyandanch, NY, United States, New York. Será de 4PM a 6PM.",
  e_desc_3: "Nuestro día social, el cual estará lleno de actividades divertidas y podrás conocer mejor a los miembros del grupo. 1434 Straight Path, Wyandanch, NY, United States, New York. Será de 4PM a 6PM.",
  e_desc_4: "Nuestro dia de cine, estaremos viendo una pelicula que te dejara con la boca abierta. 1434 Straight Path, Wyandanch, NY, United States, New York. Será de 4PM a 6PM.",
  e_desc_5: "Retiro la parroquia de Inwood, aprovecha esta increíble oportunidad para mejorar tu vida espiritual y conocer a nuestros hermanos de Inwood. Será de 2pm a 6pm. 68 Wanser Ave, Inwood, NY 11096",
  e_desc_9: "Tenemos un evento importante próximamente que no querrás perderte. Será una oportunidad fantástica para reunirnos, aprender y crecer juntos. Por favor, revisa el grupo de WhatsApp para más información y actualizaciones sobre la fecha, hora y lugar. ¡Mantente atento para más detalles!",
  e_desc_10: "¡Tenemos un emocionante viaje próximamente! Este viaje será una gran oportunidad para estrechar lazos con otros miembros y experimentar algo nuevo juntos. Por favor, revisa el grupo de WhatsApp para más información, incluyendo el itinerario, lista de empaque y cualquier preparación necesaria. Se compartirán más detalles en breve, así que mantente atento a los mensajes del grupo!",
  e_desc_11: "Vamos a jugar fútbol esta semana, pero primero necesitamos reunirnos en la iglesia. La reunión será en 1434 Straight Path, Wyandanch, NY, Estados Unidos, Nueva York, comenzando a las 4 PM, y el juego durará hasta las 6-7 PM.",
  e_desc_6: "Nuestra reunión semanal tendrá lugar en el parque Belmont, pero nos reuniremos en el parqueo de nuestra parroquia 1434 Straight Path, Wyandanch, NY, United States, New York. A las 4pm",

  
  //Contacto
  name: "Nombre",
  number: "Número",
  email: "Correo Electrónico",
  message: "Mensaje",
  submit: "Enviar",
  success: 'Formulario enviado. Nos pondremos en contacto en breve.',
  error: 'Hubo un error. Por favor, inténtalo de nuevo.',


  //Preguntas frecuentes
  faq_t_1: "¿Quiénes somos?",
  faq_desc_1: `Somos un grupo juvenil de la iglesia catolica comprometido con el crecimiento espiritual, la comunidad y el servicio.`,

  faq_t_2: "¿Cuál es el propósito de nuestro grupo juvenil?",
  faq_desc_2: `Nuestro propósito es brindar un ambiente acogedor donde los jóvenes puedan crecer en su fe, construir amistades significativas y participar en actividades que promuevan el servicio y la comunidad.`,

  faq_t_3: "¿Hay un minimo de edad para ser parte del grupo juvenil?",
  faq_desc_3: `Nuestro grupo está abierto a todos los jóvenes dentro de las edades de 18 a 35 años.`,

  faq_t_4: "¿Cuándo y dónde nos reunimos regularmente?",
  faq_desc_4: `Nuestras reuniones son todos los domingos de 4PM a 6PM, en la parroquia "Nuestra Señora de la Medalla Milagrosa", ubicada en `,

  faq_t_5: "¿Cómo puedo ponerme en contacto con el grupo juvenil?",
  faq_desc_5: "Puedes llenar el formulario de contactanos o enviarnos un mensaje a travez de nuestras redes sociales.",

  faq_t_6: "¿Es necesario ser miembro de la iglesia para unirse al grupo juvenil?",
  faq_desc_6: "El grupo juvenil esta abierto a todos, sin exclusion de religion o participacion dentro de la iglesia.  ",

    //Sobre nosotros
    full_t_1: 'Acerca de Nosotros',
    full_p_1: 'Hace siete años, Hijos de Dios fue concebido como un refugio espiritual para los jóvenes católicos en la comunidad parroquial de Wyandanch en New York. A lo largo de este tiempo, hemos experimentado un crecimiento significativo y una transformación colectiva, manteniendo firme nuestro compromiso con la fe y el servicio a los demás.',
  
    full_t_2: 'Nuestra Historia',
    full_p_2__1: 'En el año 2016, Hijos de Dios tuvo su inicio como respuesta a la necesidad de crear un espacio sagrado donde los jóvenes de habla hispana pudieran explorar y fortalecer su fe católica. Este inspirador proyecto fue concebido después de un retiro espiritual que marcó profundamente a María Rodríguez, quien sintió el llamado de Dios a iniciar un grupo juvenil dentro de nuestra comunidad hispana en Wyandanch.',
    full_p_2__2: 'La semilla de esta idea comenzó a germinar después de ese retiro espiritual transformador. El corazón inquieto de María la llevó a investigar y buscar orientación, interactuando con diversas personas para fundar las bases de este grupo juvenil. Fue durante esta búsqueda que María conoció a Estefaní, una colaboradora invaluable que la guió y la inspiró a superar uno de los mayores obstáculos iniciales. En ese momento, el párroco no veía la necesidad de un grupo de jóvenes.',
    full_p_2__3: 'Con la ayuda de Estefaní, María logró sentar las bases para el grupo juvenil en 2016. Juntas, superaron desafíos y encontraron el apoyo necesario para avanzar en esta misión. A pesar de los obstáculos iniciales, incluida la duda del párroco, María, junto con Elizabeth, Edith Salmerón, Jocelyn, José y Alex, perseveraron. A través de conversaciones y esfuerzos conjuntos, finalmente lograron convencer al párroco de la importancia y necesidad de un grupo juvenil en nuestra comunidad.',
    full_p_2__4: 'Desde entonces, cada paso que hemos dado como comunidad ha sido una aventura compartida. Hemos vivido numerosas experiencias, construyendo lazos significativos y forjando un camino común hacia el crecimiento espiritual. Este viaje ha sido posible gracias al esfuerzo conjunto y la dedicación de todos los que han contribuido a la formación y desarrollo continuo de Hijos de Dios.',
  
    full_t_3: 'Nuestra Misión',
    full_p_3: 'Enraizados en los valores fundamentales de la fe católica, nuestra misión es proporcionar un ambiente acogedor y enriquecedor donde los jóvenes puedan cultivar su relación con Dios, mientras contribuyen positivamente a la comunidad que nos rodea. Creemos en la importancia de la oración, el servicio y el amor como elementos fundamentales de nuestra identidad.',
  
    full_t_4: 'Nuestro Compromiso',
    full_p_4: 'Mantenemos un compromiso inquebrantable con la fe, la oración y el servicio a los demás. Cada actividad, evento y encuentro está impregnado de la esencia de estos valores, creando un espacio donde los jóvenes pueden crecer espiritualmente, fortalecer amistades y marcar una diferencia positiva en el mundo que nos rodea.',
  
    full_t_5: 'Únete a Nosotros',
    full_p_5__1: 'Te invitamos a unirte a nosotros en esta emocionante travesía. Ya seas nuevo en la fe o hayas estado con nosotros desde el principio, hay un lugar para ti en nuestra familia. Explora nuestras actividades, participa en nuestros eventos y descubre cómo puedes ser parte de nuestra mision mientras caminamos en direccion a nuestro "Primer Amor".',
    full_p_5__2: 'En Hijos de Dios, estamos comprometidos a seguir creciendo juntos, nutriendo nuestras almas y extendiendo el amor de Dios a todos los que nos rodean.',
    full_p_5__3: '¡Bienvenido a nuestra familia!',

  //Footer
  follow: "Síguenos",
  foot_desc: "Si eres un joven católico que busca fortalecer tu fe, servir a los demás y conectarte con personas que comparten tus valores, te invitamos a unirte a Hijos de Dios. Independientemente de tu edad o nivel de conocimiento religioso, eres bienvenido en nuestra comunidad. Esperamos verte en nuestras próximas actividades, compartiendo este emocionante viaje de fe y crecimiento con nosotros.",
  developed_by: "Sitio web desarrollado por",

  
  //Feedback
  fed_opinion: "Danos tu opinión",
  fed_tell: "Cuéntanos qué disfrutaste del grupo y si hay alguna actividad que te gustaría volver a ver. Valoramos tu retroalimentación y nos esforzamos por mejorar para nuestros miembros.",
  fed_success: "Gracias, vamos a leer cuidadosamente tu opinión.",
  fed_anonimus: "Todas las presentaciones son anónimas.",
  fed_back: "Volver a la página principal"
};


i18next
    .use(initReactI18next)
    .init({
      resources: {
        en: { translation: translationEn },
        es: { translation: translationEs }
      },
        fallbackLng: 'en',
        lng: 'en',
        interpolation: {
            escapeValue: false
        }
    })


function App() {
  const {t} = useTranslation()
  
  return (
    <div className="App">
      <Router>
        <Social />
        <Routes>
          <Route path='/' element={<Homepage t={t} />} />
          <Route path='/about' element={<AboutPage t={t} />} />
          <Route path='/feedback' element={<FeedbackPage t={t} />} />
          <Route path='*' element={<Homepage t={t} />} />
        </Routes>
        <Footer t={t} />
      </Router>
    </div>
  );
}

export default App;
