import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import './Hero.scss';

const Hero = ({ t }) => {
    const [scale, setScale] = useState(1.2);

    const updateScale = () => {
        let newScale;
        if (window.innerWidth < 500) {
            newScale = 2.8 * (1.5 - 0.5 * (window.innerWidth / 1920));
        } else {
            newScale = 1.9 - 0.1 * (window.innerWidth / 1920)
        }
        setScale(newScale);
    };

    useEffect(() => {
        updateScale();
        window.addEventListener('resize', updateScale);
        return () => window.removeEventListener('resize', updateScale);
    }, []);

    return (
        <section className="hero" id="hero">
            <div className="hero__video">
                <ReactPlayer
                    url="https://player.vimeo.com/video/993118958?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    playing
                    loop
                    muted
                    width="100%"
                    height="100%"
                    className="hero__video--background"
                    style={{ transform: `scale(${scale})` }}
                />
            </div>
            <div className="hero__text">
                <h1 className="hero__text--title">Hijos de Dios</h1>
                <a href="#contact" className="hero__text--button">
                    <button>{t('contact')}</button>
                </a>
            </div>
        </section>
    );
}

export default Hero;
