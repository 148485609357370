import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.scss';
import logo from '../../assets/logo/icon-final.png';

const Header = ({t}) => {
    const [isNavActive, setIsNavActive] = useState(false);

    useEffect(() => {
        // Function to be executed when the intersection occurs
        function handleIntersection(entries, observer) {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setIsNavActive(false);
                } else {
                    setIsNavActive(true);
                }
            });
        }
        const options = {
            root: null, 
            rootMargin: '0px', 
            threshold: 0.3 // Trigger when 50% of the element is visible
        };

        const observer = new IntersectionObserver(handleIntersection, options);
        const testimonialsElement = document.getElementById('hero');
        observer.observe(testimonialsElement);

        // Cleanup: Stop observing when the component unmounts
        return () => {
            observer.unobserve(testimonialsElement);
        };
    }, []); 

    return (
        <>
            <nav className={`nav ${isNavActive ? 'nav--active' : ''}`}>
                <div className='nav__logo'>
                    <Link to="/" onClick={() => window.scrollTo(0, 0)}>
                        <img className='nav__logo--img' src={logo} alt="hijos de dios logo" />
                    </Link>
                </div>

                <div className='nav__links'>
                    {/* <a className='nav__links--item' href="/">Live</a> */}
                    <a className='nav__links--item' href="#events">{t('events')}</a>
                    <a className='nav__links--item' href="#contact">{t('contact')}</a>

                    <Link className='nav__links--item' to="/about" onClick={() => window.scrollTo(0, 0)}>
                        {t('about')}
                    </Link>
                </div>
            </nav>
        </>
    )
}

export default Header;