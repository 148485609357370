import Header from '../components/Header/Header';
import Events from "../components/Events/Events";
import Hero from "../components/Hero/Hero";
import Testimonials from '../components/Testimonials/Testimonials';
import AboutUs from '../components/AboutUs/AboutUs';
import FAQ from '../components/FAQ/FAQ';
import ContactUs from '../components/ContactUs/ContactUs';

const Homepage = ({t}) => {
    return(
        <>
            <Header t={t}/>

            <Hero t={t}/>
            <Testimonials t={t}/>
            <AboutUs t={t}/>
            <Events t={t}/>
            <FAQ t={t}/>
            <ContactUs t={t}/>
        </>
    )
}

export default Homepage;