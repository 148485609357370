import React, { useState } from "react";
import axios from "axios";
import './Feedback.scss';
import { Link } from "react-router-dom";

const FORMSPARK_ACTION_URL = "https://submit-form.com/7QUyCNrSm"; 

const Feedback = ({ t }) => {
    const [message, setMessage] = useState('');
    
  const [formData, setFormData] = useState({
    message: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setMessage('loading')
      await axios.post(FORMSPARK_ACTION_URL, formData);
      setMessage('success');
      setFormData({
        message: "",
      });

    } catch (error) {
      console.error("Error submitting form:", error);
      setMessage('error');
    }
  };

  return (
    <section className='feedback' id='contact'>
      <h2 className='feedback__title'>{t("fed_opinion")}</h2>

      <form className='f_form' onSubmit={handleSubmit}>
        <p className="f_form__paragraph f_form__paragraph--spacing">{t("fed_tell")}</p>

        <label className='f_form__label' htmlFor="message">{t("message")}</label>

        <textarea 
            rows="12" 
            cols="50"
            className='f_form__input'
            type="text"
            id='message'
            name='message'
            value={formData.message}
            onChange={handleChange}
            required 
            disabled={message === 'success' ? true : false}
        >
        </textarea>

        {!message && <button className='f_form__button' type='submit'>{t("submit")}</button>}
        {message === 'success' && <div className="f-response f-response--success">{t("fed_success")}</div>}
        {message === 'error' && 
        <>
            <div className="f-response f-response--error">{t("error")}</div>
            <button className='f_form__button' type='submit'>{t("submit")}</button>
        </>}
      </form>

      {message !== 'success' ?  <p className="f_form__paragraph f_form__paragraph--spacing">{t("fed_anonimus")}</p> : ''}
      {message === 'success' ? <Link to={'../'} className="f_form__link"  onClick={() => window.scrollTo(0, 0)}>{t('fed_back')}</Link> : ''}
    </section>
  );
};

export default Feedback;
