import React, { useState } from "react";
import axios from "axios";
import './ContactUs.scss';

const FORMSPARK_ACTION_URL = "https://submit-form.com/VRLnxsTDz"; 

const ContactUs = ({ t }) => {
    const [message, setMessage] = useState('');
    
  const [formData, setFormData] = useState({
    name: "",
    number: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setMessage('loading')
      await axios.post(FORMSPARK_ACTION_URL, formData);
      setMessage('success');
      setFormData({
        name: "",
        number: "",
        email: "",
        message: "",
      });

    } catch (error) {
      console.error("Error submitting form:", error);
      setMessage('error');
    }
  };

  return (
    <section className='contact' id='contact'>
      <h2 className='contact__title'>{t("contact")}</h2>

      <form className='c_form' onSubmit={handleSubmit}>
        <div className='c_form__up'>
          <div>
            <label htmlFor="name">{t("name")}</label>
            <input
              type="text"
              id='name'
              name='name'
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>

          <div>
            <label htmlFor="number">{t("number")}</label>
            <input
              type="tel"
              id="number"
              name="number"
              maxLength="12"
              value={formData.number}
              onChange={handleChange}
            />
          </div>
        </div>

        <label className='c_form__label' htmlFor="email">{t("email")}</label>
        <input
          className='c_form__input'
          type="email"
          id='email'
          name='email'
          value={formData.email}
          onChange={handleChange}
          required
        />

        <label className='c_form__label' htmlFor="message">{t("message")}</label>

        <textarea 
            rows="6" 
            cols="50"
            className='c_form__input'
            type="text"
            id='message'
            name='message'
            value={formData.message}
            onChange={handleChange}
            required
        >
        </textarea>

        {!message && <button className='c_form__button' type='submit'>{t("submit")}</button>}
        {message === 'success' && <div className="f-response f-response--success">{t("success")}</div>}
        {message === 'error' && 
        <>
            <div className="f-response f-response--error">{t("error")}</div>
            <button className='c_form__button' type='submit'>{t("submit")}</button>
        </>}
      </form>
    </section>
  );
};

export default ContactUs;
