import './FullAboutUs.scss';
import join from '../../assets/images/join-us.jpg';
import our from '../../assets/images/our-mission.jpg';


const FullAboutUs = ({t}) => {
    return(
        <section className='our-history'>
            <h2 className='our-history__title'>{t("full_t_1")}</h2>
            <p className='our-history__paragraph'>
                {t("full_p_1")}
            </p>

            <h2 className='our-history__title'>{t("full_t_2")}</h2>
            <p className='our-history__paragraph'> 
                {t("full_p_2__1")}
            <br /><br />
                {t("full_p_2__2")}
            <br /><br />
                {t("full_p_2__3")}
            <br /><br />
                {t("full_p_2__4")}
            </p>

            <h2 className='our-history__title'>{t("full_t_3")}</h2>
            <p className='our-history__paragraph'>
                {t("full_p_3")}
            </p>
            <img src={our} alt="Jesus holding the face of maria in a performance made by Hijos De Dios" />


            <h2 className='our-history__title'>{t("full_t_4")}</h2>
            <p className='our-history__paragraph'>
                {t("full_p_4")}
            </p>

            <h2 className='our-history__title'>{t("full_t_5")}</h2>
            <p className='our-history__paragraph'>
                {t("full_p_5__1")}
            <br /><br />
                {t("full_p_5__2")}
            <br /><br />
                {t("full_p_5__3")}
            </p>

            <img src={join} alt="Hijos De Dios members with a cake celebrating" />
        </section>
    )
}

export default FullAboutUs;