import './AboutUs.scss';
import { Link } from 'react-router-dom';

const AboutUs = ({t}) => {
    return(
        <section className='about'>
            <h2 className='about__title'>{t("about_us")}</h2>
            <p className='about__paragraph'>{t("a_p_1")}</p>
            <p className='about__paragraph'>{t("a_p_2")}</p>
            <Link to="/about">
                <button className='about__button'>{t("l_more")}</button>
            </Link>
        </section>
    )
}

export default AboutUs;