import { Link } from 'react-router-dom';
import './HeaderAbout.scss';
import logo from '../../assets/logo/icon-final.png';

const HeaderAbout = ({t}) => {

    return (
        <>
            <nav className={`nav nav--active`}>
                <div className='nav__logo'>
                    <Link to="/" onClick={() => window.scrollTo(0, 0)}>
                        <img className='nav__logo--img' src={logo} alt="hijos de dios logo" />
                    </Link>
                </div>

                <div className='nav__links'>
                    {/* <a className='nav__links--item' href="/">Live</a> */}
                    <Link to='/' className='nav__links--item' onClick={() => window.scrollTo(0, 0)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-left-fill" viewBox="0 -2 16 16">
                            <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z"/>
                        </svg>
                        {t("back")}
                    </Link>
                    <a className='nav__links--item' href="#contact">{t("contact")}</a>
                </div>
            </nav>
        </>
    )
}

export default HeaderAbout;