import './Events.scss';
import { v4 as uuidv4 } from 'uuid';

const Events = ({t}) => {
    const generateUniqueId = () => uuidv4();

    const eventsData = [
      {
        month: t('october'),
        day: "6",
        important: false,
        id: generateUniqueId(),
        events: [
          {
            weekday: t('sunday'),
            time: '4 PM',
            id: generateUniqueId(),
            description: t("e_desc_1"),
            description2: '',
            button: '',
            link: '',
            important: false
          }
        ]
      },
      {
        month: t('october'),
        day: "13",
        important: true,
        id: generateUniqueId(),
        events: [
          {
            weekday: t('sunday'),
            time: '4 AM',
            id: generateUniqueId(),
            description: t("e_desc_4"),
            description2: '',
            button: '',
            link: '',
            important: false
          }
        ]
      },
      {
        month: t('october'),
        day: "20",
        important: true,
        id: generateUniqueId(),
        events: [
          {
            weekday: t('sunday'),
            time: '4 PM',
            id: generateUniqueId(),
            description: t("e_desc_11"),
            description2: '',
            button: '',
            link: '',
            important: false
          }
        ]
      },
      {
        month: t('october'),
        day: "27",
        important: false,
        id: generateUniqueId(),
        events: [
          {
            weekday: t('sunday'),
            time: '4 PM',
            id: generateUniqueId(),
            description: t("e_desc_1"),
            description2: '',
            button: '',
            link: '',
            important: false
          }
        ]
      },
  
      
    ];

    
    return(
        <section className='events' id='events'>
            <h2 className='events__title'>{t("events")}</h2>

            {eventsData.map((item) => (
                <div className='e-container' key={item.id}>
                    <div className='e-container__date'>
                        <p className={`e-container__date--month ${item.important ? 'important' : ''}`}>{item.month}</p>
                        <p className={`e-container__date--day ${item.important ? 'important' : ''}`}>{item.day}</p>
                    </div>
                    
                    <div className='e-container__activities'>
                        {item.events.map((activitie) => (
                             <div className='activitie' key={activitie.id}>
                                <p className={`activitie__time ${item.important ? 'important' : '' }`}>{activitie.weekday + " " + activitie.time}</p>
                                <p className='activitie__description'>{activitie.description}</p>
                                {activitie.description2 && <p className='activitie__description2'>{activitie.description2 }</p>}
        
                                {activitie.button && <a href={activitie.link} ><button className='activitie__button'>{activitie.button}</button></a>}
                             </div>
                        ))}
                    </div>
                </div>
            ))}
        </section>
    )
}

export default Events;