import './FAQ.scss';
import Faq from "react-faq-component";

const FAQ = ({t}) => {
    const data = {
        title: "",
        rows: [
            {
                title: t("faq_t_1"),
                content: t("faq_desc_1"),
            },
            {
                title: t("faq_t_2"),
                content: t("faq_desc_2"),
            },
            {
                title: t("faq_t_3"),
                content: t("faq_desc_3"),
            },
            {
                title: t("faq_t_4"),
                content: <p>{t("faq_desc_4")} <b>{t("faq_direction")}</b></p>,
            },
            {
                title: t("faq_t_5"),
                content: <p>{t("faq_desc_5")}</p>,
            },
            {
                title: t("faq_t_6"),
                content: <p>{t("faq_desc_6")}</p>,
            },
        ],
    };
    
    const styles = {
        // bgColor: 'white',
        titleTextColor: "#161616",
        rowTitleColor: "#161616",
        // rowContentColor: 'grey',
        // arrowColor: "red",
    };
    
    const config = {
        // animate: true,
        // arrowIcon: "V",
        // tabFocus: true
    };
    return(
        <section className='faq'>
            <p className='faq__title'>FAQ</p>
            
            <Faq
                data={data}
                styles={styles}
                config={config}
            />
        </section>
    )
}

export default FAQ;